import React from "react";
import { styled } from "@mui/material/styles";
import { plusCircleIcon } from "../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

export default function FiltersList({
  search,
  setSearch,
  choosenSegment,
  choosePermanentFilter,
}) {
  const { t } = useTranslation();
  return (
    <Root className="autocomplete-filter-wrapper">
      <div className="autocomplete-filter-container">
        <InputWrapper>
          <LazyLoadImage src={plusCircleIcon} alt="add-filter" />
          <input
            placeholder={t("Search For a Filter")}
            value={
              search === ""
                ? choosenSegment?.name === undefined
                  ? choosePermanentFilter
                  : choosenSegment?.name
                : search
            }
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputWrapper>
      </div>
    </Root>
  );
}
