import React, { useState } from "react";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ImagePopup from "../ImagePopup";
import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
const FileMessage = ({ file, fileType, className }) => {
  const [open, setOpen] = useState(false);
  const newFile = typeof file === "object" ? URL.createObjectURL(file) : file;
  const handleClose = () => {
    setOpen(false);
  };
  const saveFile = () => {
    saveAs(newFile);
  };

  const builder = () => {
    return fileType === undefined ? (
      ""
    ) : fileType && fileType?.includes("image") ? (
      fileType && fileType?.includes("svg") ? (
        <div className="video-wmv">
          <div>
            <ImageOutlinedIcon />
            <span>Image</span>
          </div>
          <FileDownloadOutlinedIcon
            onClick={saveFile}
            className="download-file-icon"
          />
        </div>
      ) : (
        <React.Fragment>
          <LazyLoadImage
            className={className ? className : ""}
            src={newFile}
            alt="message-file"
            onClick={() => setOpen(true)}
          />
          <ImagePopup open={open} handleClose={handleClose} img={newFile} />
        </React.Fragment>
      )
    ) : fileType && fileType?.includes("pdf") ? (
      <div className="video-wmv">
        <div>
          <PictureAsPdfOutlinedIcon />
          <span>PDF</span>
        </div>
        <FileDownloadOutlinedIcon
          onClick={saveFile}
          className="download-file-icon"
        />
      </div>
    ) : fileType && fileType?.includes("video") ? (
      fileType && fileType?.includes("wmv") ? (
        <div className="video-wmv">
          <div>
            <VideocamOutlinedIcon />
            <span>Video</span>
          </div>
          <FileDownloadOutlinedIcon
            onClick={saveFile}
            className="download-file-icon"
          />
        </div>
      ) : (
        <video controls className={className ? className : ""}>
          <source src={newFile} type={fileType && fileType} />
          <source src={newFile} type="video/ogg" codecs="theora,vorbis" />
          <source src={newFile} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      )
    ) : fileType && fileType?.includes("audio") ? (
      <audio controls style={{ width: "100%" }}>
        <source src={newFile} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    ) : fileType && fileType?.includes("iframe") ? (
      <div className="video-image-container">
        <ReactPlayer
          url={file}
          width={"100%"}
          height={"auto"}
          className="react-player"
        />
      </div>
    ) : fileType && fileType?.includes("iframe") ? (
      <div className="video-image-container">
        <ReactPlayer
          url={file}
          width={"100%"}
          height={"auto"}
          className="react-player"
        />
      </div>
    ) : (fileType && fileType?.includes("wordprocessingml")) ||
      (fileType && fileType?.includes("msword")) ? (
      <div className="video-wmv">
        <div>
          <ArticleOutlinedIcon />
          <span>Word document</span>
        </div>
        <FileDownloadOutlinedIcon
          onClick={saveFile}
          className="download-file-icon"
        />
      </div>
    ) : (fileType && fileType?.includes("presentationml")) ||
      (fileType && fileType?.includes("powerpoint")) ? (
      <div className="video-wmv">
        <div>
          <ArticleOutlinedIcon />
          <span>Power point file</span>
        </div>
        <FileDownloadOutlinedIcon
          onClick={saveFile}
          className="download-file-icon"
        />
      </div>
    ) : fileType && fileType?.includes("pdf") ? (
      <div className="pdf-file">
        <div>
          <ArticleOutlinedIcon />
          <span>Pdf file</span>
        </div>
        <FileDownloadOutlinedIcon
          onClick={saveFile}
          className="download-file-icon"
        />
      </div>
    ) : fileType && fileType?.includes("octet-stream") ? (
      <div className="video-wmv">
        <div>
          <VideocamOutlinedIcon />
          <span>Stream</span>
        </div>
        <FileDownloadOutlinedIcon
          onClick={saveFile}
          className="download-file-icon"
        />
      </div>
    ) : fileType && fileType?.includes("pdf") ? (
      <div className="pdf-file">
        <div>
          <ArticleOutlinedIcon />
          <span>Pdf file</span>
        </div>
        <FileDownloadOutlinedIcon
          onClick={saveFile}
          className="download-file-icon"
        />
      </div>
    ) : fileType && fileType?.includes("octet-stream") ? (
      <div className="video-wmv">
        <div>
          <VideocamOutlinedIcon />
          <span>Stream</span>
        </div>
        <FileDownloadOutlinedIcon
          onClick={saveFile}
          className="download-file-icon"
        />
      </div>
    ) : (
      "File not suppoted yet"
    );
  };
  return builder();
};

export default FileMessage;
